// extracted by mini-css-extract-plugin
export var yellowWrapper = "styles-module--yellow-wrapper--afpJR";
export var blueWrapper = "styles-module--blue-wrapper--L1x+Z";
export var dropdownBlock = "styles-module--dropdown-block--LqKQ7";
export var imageBlock = "styles-module--image-block--A4fZ0";
export var imageContainer = "styles-module--image-container--RVRA3";
export var hiddenContainer = "styles-module--hidden-container--bCY67";
export var footBar = "styles-module--foot-bar--Rr1bx";
export var navButton = "styles-module--nav-button--faLS4";
export var navButtonTop = "styles-module--nav-button-top--cu-e8";
export var navButtonPrev = "styles-module--nav-button-prev--gyGog";
export var navButtonNext = "styles-module--nav-button-next--Vbzcm";
export var navButtonBottom = "styles-module--nav-button-bottom--nOn-H";
export var donationBut = "styles-module--donation-but--geOWS";
export var pageText = "styles-module--page-text--F2XkR";
export var inlineIcon = "styles-module--inline-icon--LnpbV";