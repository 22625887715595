import React, { useContext, useEffect, useState } from 'react'

// Components
import NewComment from '../../molecules/NewComment'
import CommentCard from '../../molecules/CommentCard'

// Utils
import { getFont } from '../../../utils/utils'

// Context
import LocalizationContext from '../../../context/Localization/Localization.context'

// Models
import { CommentModel } from '../../../models/Comment.type'

// Services
import { getComments } from '../../../services/comments'

// Styles
import {
  container,
  commentsTitle,
} from './styles.module.scss'

interface Props {
  id: number
  localization: {
    comments: string
    new_comment: string
    send: string
  }
}

const CommentArea: React.FC<Props> = ({ localization, id }) => {
  const { language } = useContext(LocalizationContext)
  const [comments, setComments] = useState<CommentModel[]>([])

  const updateComments = async () => {
    const newComment = await getComments(id, language)
    setComments(newComment)
  }

  useEffect(() => {
    updateComments()
  }, [language])

  return (
    <div className={container}>
      <h2 className={`${commentsTitle} ${getFont('font-patua')}`}>
        {localization.comments}
      </h2>
      <NewComment
        newComments={localization.new_comment}
        send={localization.send}
        id={id}
        update={updateComments}
      />
      {comments.map((comment) => {
        return (
          <CommentCard
            key={`Comment_card_${comment.username}_${comment.date}`}
            username={comment.username}
            date={comment.date}
            text={comment.comment}
            image={comment.image}
          />
        )
      })}
    </div>
  )
}

export default CommentArea
