import React, { useContext, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

// Components
import TextArea from '../../atoms/TextArea'
import DilanButton from '../../atoms/DilanButton'

// Utils
import { getFont } from '../../../utils/utils'

// Context
import UserContext from '../../../context/User/User.context'
import LocalizationContext from '../../../context/Localization/Localization.context'

// Services
import { postComments } from '../../../services/comments'

// Constants
import { USER_TOKEN } from '../../../config/constants/localStorage'

// Styles
import { container, imgComment, sendButton } from './styles.module.scss'

interface Props {
  id: number
  send?: string
  newComments: string
  update: () => void
}

interface FormData {
  username: string
  comment: string
}

const NewComment: React.FC<Props> = ({ newComments, send, id, update }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const { user } = useContext(UserContext)
  const { language } = useContext(LocalizationContext)

  const [hasComment, setHasComment] = useState(false)

  const { register, handleSubmit, setValue } = useForm<FormData>({
    mode: 'onChange',
  })

  const handleForm: SubmitHandler<FormData> = async (data) => {
    try {
      const token = executeRecaptcha
        ? await executeRecaptcha('New_Comment')
        : ''
      await postComments(localStorage.getItem(USER_TOKEN) as string, {
        ...data,
        token,
        chapterId: id,
        language,
      })
      setValue('comment', '')
      setHasComment(false)
      update()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <form onSubmit={handleSubmit(handleForm)}>
      <input className="hidden" {...register('username')} />
      <div className={container}>
        <img
          className={imgComment}
          src={`https://dilancovak.com/.img/users/User${
            user?.image ? user.image : 1000
          }.jpg`}
        />
        <TextArea
          className={`${getFont('font-lato')}`}
          placeholder={newComments}
          limit={1000}
          register={register('comment', {
            onChange: (e) => {
              setHasComment(e.target.value.length > 0)
            },
          })}
        />
      </div>
      {hasComment && (
        <DilanButton
          type="primary"
          className={`${sendButton} ${getFont('font-lato')}`}
          handleClick={() => {}}
        >
          {send}
        </DilanButton>
      )}
    </form>
  )
}

export default NewComment
