import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowUp,
  faEyeSlash,
  faArrowDown,
  faChevronUp,
  faChevronDown,
  faEllipsisV,
} from '@fortawesome/free-solid-svg-icons'

// Styles
import {
  navButton,
  navButtonTop,
  navButtonPrev,
  navButtonNext,
  navButtonClose,
  navButtonBottom,
  navButtonInactive,
} from './styles.module.scss'

interface Props {
  setPage: (page: number) => void
  scrollToPage: (page: number) => void
  page: number
  pages: number
}
const ListNav: React.FC<Props> = ({ setPage, scrollToPage, page, pages }) => {
  const [open, setOpen] = useState(true)
  return (
    <>
      <div
        onClick={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }}
        className={`${navButton} ${open ? navButtonTop : navButtonInactive}`}
      >
        <FontAwesomeIcon icon={faArrowUp} />
      </div>
      <div
        className={`${navButton} ${open ? navButtonPrev : navButtonInactive}`}
        onClick={() => {
          if (page > 1) {
            scrollToPage(page - 1)
            setPage(page - 1)
          }
        }}
      >
        <FontAwesomeIcon icon={faChevronUp} />
      </div>
      <div
        className={`${navButton} ${open ? navButtonNext : navButtonInactive}`}
        onClick={() => {
          if (page < pages) {
            scrollToPage(page + 1)
            setPage(page + 1)
          }
        }}
      >
        <FontAwesomeIcon icon={faChevronDown} />
      </div>
      <div
        onClick={() => {
          scrollToPage(pages)
        }}
        className={`${navButton} ${open ? navButtonBottom : navButtonInactive}`}
      >
        <FontAwesomeIcon icon={faArrowDown} />
      </div>
      <div
        onClick={() => {
          setOpen(!open)
        }}
        className={`${navButton} ${open ? navButtonClose : navButtonInactive}`}
      >
        <FontAwesomeIcon icon={open ? faEyeSlash : faEllipsisV} />
      </div>
    </>
  )
}

export default ListNav
