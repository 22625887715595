import React from 'react'

// Utils
import { getFont } from '../../../utils/utils'

// Styles
import {
  dateText,
  textPanel,
  infoPanel,
  container,
  imgComment,
  usernameText,
  commentPanel,
} from './styles.module.scss'

interface Props {
  username: string
  date: string
  text: string
  image: number
}

const CommentCard: React.FC<Props> = ({ username, date, text, image }) => {
  return (
    <div className={container}>
      <img
        className={imgComment}
        src={`https://dilancovak.com/.img/users/User${image}.jpg`}
      />
      <div className={textPanel}>
        <div className={infoPanel}>
          <div className={`${usernameText} ${getFont('font-lato')}`}>
            {username}
          </div>
          <div className={`${dateText} ${getFont('font-lato')}`}>
            {date?.split('T')[0]}
          </div>
        </div>
        <div className={`${commentPanel} ${getFont('font-lato')}`}>{text}</div>
      </div>
    </div>
  )
}

export default CommentCard
