import React, { useState } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'

// Utils
import { getFont } from '../../../utils/utils'

// Styles
import { container, textArea, textLimit } from './styles.module.scss'

interface Props {
  limit: number
  className: string
  placeholder: string
  register: UseFormRegisterReturn
}

const TextArea: React.FC<Props> = ({
  className,
  placeholder,
  limit,
  register,
}) => {
  const [wordCount, setWordCount] = useState(0)

  const { onChange, name } = register

  return (
    <div className={`${container} relative`}>
      <textarea
        className={`${className} ${textArea}`}
        placeholder={placeholder}
        {...register}
        onChange={(event) => {
          if (event.target.value.length > limit) {
            event.target.value = event.target.value.substring(0, limit)
          }
          setWordCount(event.target.value.length)
          onChange(event)
        }}
      />
      <div className={`${textLimit} ${getFont('font-lato')}`}>
        {wordCount}/<span>{limit}</span>
      </div>
    </div>
  )
}

export default TextArea
