import Axios from 'axios'
import { CommentModel } from '../models/Comment.type'

const { ENDPOINT } = process.env

interface CommentData {
  token: string
  language: string
  comment: string
  chapterId: number
  username: undefined | string
}

export const postComments = (
  token: string,
  data: CommentData
): Promise<number> =>
  Axios.post(`${ENDPOINT}/comment`, data, { headers: { token } }).then(
    (res) => res.data.result
  )

export const getComments = (
  id: number,
  lang: string
): Promise<CommentModel[]> =>
  Axios.get(`${ENDPOINT}/comment?chapter=${id}&language=${lang}`).then(
    (res) => res.data.result
  )
